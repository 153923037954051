/* src/index.css */
@layer utilities {
  .text-shadow-neon {
    text-shadow: 0 0 5px #ff5733, 0 0 10px #ff5733, 0 0 15px #ff5733;
  }
}

/* Import Yozakura Fonts */
@font-face {
  font-family: "Yozakurajp";
  src: url("./fonts/YozakuraJP-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Old+Mincho&display=swap");

/* Import Tailwind CSS */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  min-height: 100vh;
  background-color: black;
}

.yozakura {
  font-family: "Yozakurajp", sans-serif;
}

.crimson {
  font-family: "Crimson Text", serif;
}

.zen {
  font-family: "Zen Old Mincho", serif;
}
@keyframes tiltAnimation {
  0% {
    transform: rotateZ(0deg);
  }
  25% {
    transform: rotateZ(10deg);
  }

  75% {
    transform: rotateZ(-10deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
